<template>
  <b-modal
    :id="id"
    size="sm"
    title="Xem nội dung trả lại"
    centered
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    @ok="handleReject"
    @hidden="resetData"
  >
    <div class="modal-accept">
      <b-form-group>
        <label>Nội dung trả lại</label>
        <b-form-textarea
          id="InputHelp"
          v-model="reasonReject"
          rows="3"
          no-resize
        />
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BFormGroup, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormTextarea,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    isNotification: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reasonReject: null,
    }
  },
  methods: {
    handleReject() {
      this.$emit('handleReject', this.reasonReject)
    },

    resetData() {
      this.reasonReject = null
    },
  },
}
</script>

<style lang="scss">
.modal-accept {
  .btn-button-file {
    width: 45%;
    height: 38px;
  }
      .input-file {
      display: none;
    }
}
</style>
