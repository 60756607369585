<template>
  <div id="filter-custom-manage">
    <div class="page-container mb-2">
      <h3 class="header">
        Bộ lọc
      </h3>
      <b-row>
        <!-- Người lao động -->
        <b-col
          v-if="userData.roleIdentity !=='EducationDepartment'"
          md="4"
          xl="4"
        >
          <b-form-group>
            <label
              for="basicInput"
            >Doanh nghiệp </label>
            <v-select
              :reduce="label => label.id"
              label="name"
              :options="dataAllBusiness || []"
              :placeholder="'Doanh nghiệp'"
              @input="filterBussiness"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label
              for="basicInput"
            >Trạng thái</label>
            <v-select
              :reduce="label => label.value"
              label="name"
              :options="dataStatus || []"
              :placeholder="'Trạng thái'"
              @input="filterStatus"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label
              for="basicInput"
            >Trạng thái người tai nạn</label>
            <v-select
              :reduce="label => label.en"
              label="vi"
              :options="accidentWorkerStatus || []"
              :placeholder="'Trạng thái người tai nạn'"
              @input="filterStatusWorkerStatus"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

      </b-row>
    </div>

    <div class="page-container-table">
      <!--Phần header của bảng -->
      <button-all-header
        :contentBtnAdd="'Thêm tai nạn lao động'"
        :arrayExcel="[]"
        :showBtnMultiDelete="showBtnMultiDelete"
        class="custtom-header mr-2"
        :hideImportFile="false"
        :hideExportFile="true"
        :hideDowload="false"
        @clickExportExcel="exportExcel"
        @clickDelete="deleteItems"
        @search="search($event)"
        @clickAdd="showModalAddAccident"
      />
      <!--Phần Bảng -->
      <template>
        <vue-good-table
          ref="user-table"
          :columns="columns"
          :rows="dataTable"
          :sort-options="{
            enabled: false,
          }"
          :select-options="{
            enabled: true,
            vertialAlignTop: true,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true,
          }"
          @on-selected-rows-change="selectRowTable"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column:Mã đơn vị -->
            <span
              v-if="props.column.field === 'code'"
              class="text-nowrap"
            >
              <b-badge
                pill
                class="light-code"
              >
                <span class="text-name">{{ props.row.code }}</span>
              </b-badge>
            </span>

            <span v-else-if="props.column.field === 'accidentTime'">
              <span>
                {{ props.row.accidentTime | formatDateToDDMMHHMM }}
              </span>
            </span>

            <!-- statusString : Trạng thái  -->
            <span
              v-else-if="props.column.field == 'accidentStatusString' "
            >
              <b-badge
                pill
                :variant="statusVariant(props.row.accidentStatus)"
                class="border-status"
              >
                <span> {{ props.row.accidentStatusString }}</span>

              </b-badge>

            </span>

            <!-- Chức năng -->
            <span v-else-if="props.column.field == 'cn'">
              <feather-icon
                v-b-tooltip.hover.top="'Xem hồ sơ xử lý tai nạn'"
                icon="FileTextIcon"
                size="18"
                class="mr-2"
                @click="showOpenFile(props.row.id)"
              />
              <feather-icon
                v-b-tooltip.hover.top="'Xem danh sách người lao động bị tai nạn'"
                icon="EyeIcon"
                size="18"
                @click="showFile(props.row.id)"
              />
              <span
                v-if="!props.row.bussiness"
                class="ml-2"
              >
                <feather-icon
                  v-b-tooltip.hover.top="'Chỉnh sửa tai nạn lao động'"
                  icon="EditIcon"
                  size="18"
                  @click="showModalEditAccident(props.row.id)"
                />
              </span>
              <span
                v-if="props.row.accidentStatus !== 'Send'"
                class="disable-icon"
              >
                <feather-icon
                  v-if="props.row.filePath"
                  v-b-tooltip.hover.top="'Tải xuống'"
                  icon="DownloadIcon"
                  size="18"
                  class="text-body ml-2"
                  @click="downloadFile(props.row.id, props.row.fileName)"
                />
                <feather-icon
                  v-if="!props.row.filePath"
                  v-b-tooltip.hover.top="'Không có file tải xuống'"
                  icon="DownloadIcon"
                  size="18"
                  class="text-disable ml-2"
                />
              </span>

              <span v-if="['Send'].includes(props.row.accidentStatus) && userData.roleIdentity !=='EducationDepartment'">
                <b-dropdown
                  variant="link"
                  boundary="viewport"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="18"
                      class="text-body"
                    />
                  </template>

                  <b-dropdown-item
                    @click="openModalContent(props.row.id)"
                  >
                    <feather-icon
                      icon="SendIcon"
                      size="16"
                      class="text-body mr-50"
                    />
                    <span>{{ 'Xử lý tai nạn' }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    @click="openModalReject(props.row.id)"
                  >
                    <feather-icon
                      icon="CornerUpLeftIcon"
                      size="16"
                      class="text-body mr-50"
                    />
                    <span>{{ 'Trả lại' }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
          </template>
        </vue-good-table>
        <my-pagination
          :totalItems="totalRecord"
          :currentPage="urlQuery.pageNumber"
          @pageClick="handlePageClick"
        />
      </template>
    </div>
    <Show-file
      :id="modalDetailUser"
      :userData="userData"
      :dataTableAccident="dataTableAccident"
      :totalRecordAccident="totalRecordAccident"
      :urlQueryAccident="urlQueryAccident"
      @search="searchAccident"
      @handlePageClick="handlePageClickAccident"
    />

    <modal-folder-accident
      :id="modalFolderAccident"
      :dataTableFileAccident="dataTableFileAccident"
      :totalRecordFile="totalRecordFile"
      :urlQueryFile="urlQueryFile"
      @search="searchFileAccident"
      @handlePageClick="handlePageClickFileAccident"
    />

    <!--Nội dung trả lại-->
    <modal-show-reject
      :id="confirmModalRejectId"
      @handleReject="handleReject"
    />

    <!--Nội dung xử lý-->
    <modal-show-content
      :id="confirmModalContentId"
      @handleSend="handleSend"
    />

    <modal-accident
      id="modal-add-accident"
      title="Thêm tai nạn lao động"
    />
    <!-- @getListAccident="fetchAccidentBusiness(urlQuery)" -->

    <!-- modal xóa-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />
  </div>
</template>

<script>
import { formatDateToDDMMHHMM } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BFormGroup, BBadge, BDropdown,
  BDropdownItem, VBTooltip,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import ModalAccident from './components/ModalAccident.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ShowFile from './components/ShowFile.vue'
import ModalFolderAccident from './components/ModalFolderAccident.vue'
import eventBus from '@/libs/event-bus'
import ModalShowReject from './components/ModalShowReject.vue'
import ModalShowContent from './components/ModalShowContent.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    VSelect,
    ConfirmModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    ShowFile,
    ModalFolderAccident,
    ModalShowReject,
    ModalShowContent,
    ModalAccident,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMMHHMM,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      modalDetailUser: 'showFile',
      modalFolderAccident: 'folder-accident',
      confirmModalRejectId: 'confirm-modal-reject',
      confirmModalContentId: 'confirm-modal-content',
      // ==dữ liệu bảng
      rows: [],
      columns: [
        // {
        //   label: 'MÃ TAI NẠN Lao Động',
        //   field: 'code',
        //   sortable: false,
        // },
        {
          label: 'MÃ VỤ TAI NẠN LAO ĐỘNG',
          field: 'code',
          sortable: false,
        },
        {
          label: 'THỜI GIAN XẢY RA VỤ TAI NẠN',
          field: 'accidentTime',
          sortable: false,
        },
        {
          label: 'DOANH NGHIỆP',
          field: 'bussiness',
          sortable: false,
        },
        {
          label: 'NƠI XẢY RA TAI NẠN',
          field: 'location',
          sortable: false,
        },
        {
          label: 'TRẠNG THÁI',
          field: 'accidentStatusString',
          sortable: false,
        },
        {
          label: 'TRẠNG THÁI NGƯỜI TAI NẠN',
          field: 'accidentWorkerStatus',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'cn',
          sortable: false,
          width: '200px',
        },
      ],
      // ==phân trang
      totalRecord: 0,
      totalRecordAccident: 0,
      totalRecordFile: 0,
      currentPage: 0,
      urlQuery: {
        bussinessId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
        isBussiness: false,
        status: null,
        accidentQueryStatus: null,
      },
      accidentWorkerStatus: [
        {
          vi: 'Tai nạn có 2 người bị thương nặng trở lên',
          en: 'Serious',
        },
        {
          vi: 'Tai nạn có người chết',
          en: 'Death',
        },
      ],
      urlQueryAccident: {
        accidentId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      urlQueryFile: {
        accidentId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      dataTable: [],
      dataTableAccident: [],
      dataTableFileAccident: [],
      accidentId: null,
      showBtnMultiDelete: false,
      dataStatus: [
        {
          value: 'NotSend',
          name: 'Chưa gửi',
        },
        {
          value: 'Send',
          name: 'Đã gửi',
        },
        {
          value: 'Approve',
          name: 'Đã xử lý',
        },
        {
          value: 'Reject',
          name: 'Trả lại',
        },
      ],
      confirmModalId: 'modalConfirmDelete',
      modalContent: '',
      deleteIds: [],
    }
  },
  // ======================WATCH=============================
  // ==========================COMPUTED=======================
  computed: {
    ...mapGetters('performTraining', ['dataAllBusiness']),

    statusVariant() {
      const statusColor = {
        NotSend: 'secondary',
        Send: 'primary',
        Approve: 'success',
        Reject: 'danger',
      }
      return status => statusColor[status]
    },
  },
  // ===========================CREATED====================
  mounted() {
    this.fetchDataComboboxAllBusiness()
  },
  created() {
    this.fetchAccidentBusiness(this.urlQuery)
    eventBus.$on('reloadTable', this.fetchAccidentBusiness)
  },
  destroyed() {
    eventBus.$off('reloadTable', this.fetchAccidentBusiness)
  },
  // =============================METHOD======================
  methods: {
    ...mapActions('performTraining', ['fetchDataComboboxAllBusiness']),
    showModalAddAccident() {
      this.$bvModal.show('modal-add-accident')
    },

    showModalEditAccident(id) {
      this.$bvModal.show('modal-add-accident')
      this.$nextTick(() => {
        eventBus.$emit('getDataEdit', id)
      })
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_ACCIDENT, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchAccidentBusiness(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Download file
    downloadFile(id, name) {
      axiosApiInstance({
        url: ConstantsApi.DOWNLOAD_ACCIDENT, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { id },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // Lấy thông tin khai báo tai nạn lao động theo id
    async openModalReject(id) {
      this.accidentId = id
      this.$bvModal.show(this.confirmModalRejectId)
    },

    // Mở modal nội dung xử lý
    async openModalContent(id) {
      this.accidentId = id
      this.$bvModal.show(this.confirmModalContentId)
    },

    // gửi nội dung xử lý
    async handleSend(val) {
      val.append('Id', this.accidentId)
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.APPROVE_ACCIDENT, val).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xử lý thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.confirmModalContentId)
        this.fetchAccidentBusiness(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xử lý không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // gửi nội dung trả lại
    async handleReject(val) {
      const model = {
        id: this.accidentId,
        reasonReject: val,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.REJECT_ACCIDENT, model).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Trả lại thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.$bvModal.hide(this.confirmModalRejectId)
        this.fetchAccidentBusiness(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Trả lại không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Danh sách tai nạn lao động
    async fetchAccidentBusiness(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_MANAGEMENT_ACCIDENT, {
        params: urlQuery,
      })
      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchAccidentBusiness(this.urlQuery)
    },

    // lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchAccidentBusiness(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchAccidentBusiness(this.urlQuery)
      }
    },

    filterStatus(val) {
      this.urlQuery.pageNumber = 1
      this.urlQuery.status = val
      this.fetchAccidentBusiness(this.urlQuery)
    },

    // Xử lý khi ấn nút phân trang
    handlePageClickAccident(pageNumber, pageSize) {
      this.urlQueryAccident.pageNumber = pageNumber
      this.urlQueryAccident.pageSize = pageSize
      this.fetchAccidentWorker(this.urlQueryAccident)
    },

    // lấy danh sách khi tìm kiếm
    searchAccident(val) {
      if (val != null) {
        this.urlQueryAccident.pageNumber = 1
        this.urlQueryAccident.key = val
        this.fetchAccidentWorker(this.urlQueryAccident)
      } else {
        this.urlQueryAccident.pageNumber = 1
        this.urlQueryAccident.key = ''
        this.fetchAccidentWorker(this.urlQueryAccident)
      }
    },

    // Xử lý khi ấn nút phân trang
    handlePageClickFileAccident(pageNumber, pageSize) {
      this.urlQueryFile.pageNumber = pageNumber
      this.urlQueryFile.pageSize = pageSize
      this.fetchFileAccident(this.urlQueryFile)
    },

    // lấy danh sách khi tìm kiếm
    searchFileAccident(val) {
      if (val != null) {
        this.urlQueryFile.pageNumber = 1
        this.urlQueryFile.key = val
        this.fetchFileAccident(this.urlQueryFile)
      } else {
        this.urlQueryFile.pageNumber = 1
        this.urlQueryFile.key = ''
        this.fetchFileAccident(this.urlQueryFile)
      }
    },

    // lọc theo tình trạng tai nạn
    filterStatusWorkerStatus(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.accidentQueryStatus = val
        this.fetchAccidentBusiness(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.accidentQueryStatus = ''
        this.fetchAccidentBusiness(this.urlQuery)
      }
    },

    // Lọc theo doanh nghiệp
    filterBussiness(val) {
      if (val != null) {
        this.urlQuery.bussinessId = val
        this.urlQuery.pageNumber = 1
        this.fetchAccidentBusiness(this.urlQuery)
      } else {
        this.urlQuery.bussinessId = ''
        this.urlQuery.pageNumber = 1
        this.fetchAccidentBusiness(this.urlQuery)
      }
    },

    // Hiển thị danh sách người lao động bị tai nạn
    showFile(id) {
      this.urlQueryAccident.accidentId = id
      this.fetchAccidentWorker(this.urlQueryAccident)
      this.$bvModal.show(this.modalDetailUser)
    },

    // Hiển thị danh sách file tai nạn
    showOpenFile(id) {
      this.urlQueryFile.accidentId = id
      this.fetchFileAccident(this.urlQueryFile)
      this.$bvModal.show(this.modalFolderAccident)
    },

    // Danh sách người lao động bị tai nạn
    async fetchAccidentWorker(urlQueryAccident) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_ACCIDENT_WORKER, {
        params: urlQueryAccident,
      })

      this.dataTableAccident = data?.data?.pageLists
      this.totalRecordAccident = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Danh sách file tai nạn
    async fetchFileAccident(urlQueryFile) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_ACCIDENT_FILE, {
        params: urlQueryFile,
      })

      this.dataTableFileAccident = data?.data?.pageLists
      this.totalRecordFile = data?.data?.totalRecord
      this.$hideLoading()
    },
    exportExcel() {
      axiosApiInstance({
        url: ConstantsApi.EXPORT_EXCEL, // your url
        method: 'GET',
        responseType: 'blob',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'DanhSachTaiNanLaoDong.xlsx') // or any other extension
        document.body.appendChild(link)
        link.click()
      }).catch(e => {
        this.$root.$bvToast.toast('Không tìm thấy file', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
  },
}
</script>

<style lang="scss">
#filter-custom-manage {
  .actiontd{
    text-align: center;
  }
  .actionth{
    text-align: center;
  }
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .text-disable {
    color: #797E84;
  }
}
</style>
