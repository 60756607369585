<template>
  <b-modal
    :id="id"
    :title="title"
    cancel-variant="outline-primary"
    size="xl"
    cancel-title="Quay lại"
    ok-title="Lưu"
    no-close-on-backdrop
    @ok="handlerAddAccident($event)"
    @hidden="resetData"
  >
    <declare-accident />
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import eventBus from '@/libs/event-bus'
import DeclareAccident from '@/views/occupational-safety-health/declare-accident/pages/Create.vue'

export default {
  components: {
    DeclareAccident,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    handlerAddAccident(e) {
      eventBus.$emit('handlerAddAccident', e)
      // this.$emit('getListAccident')
    },
    resetData() {
      eventBus.$emit('resetData')
    },
  },
}
</script>

<style>

</style>
