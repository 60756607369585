<template>
  <b-modal
    :id="id"
    size="sm"
    title="Xử lý tai nạn"
    centered
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    @ok="handleSend"
    @hidden="resetData"
  >
    <div class="modal-accepted">
      <b-form-group>
        <label>Nội dung xử lý</label>
        <b-form-textarea
          id="InputHelp"
          v-model="dataInput.content"
          rows="3"
          no-resize
        />
      </b-form-group>

      <b-form-group>
        <div class="modal-accept">
          <b-form-group>
            <label>Chọn file</label>
            <div class="d-flex">
              <b-form-input
                v-model="dataInput.nameFile"
                :disabled="true"
              >
                {{ dataInput.nameFile }}
              </b-form-input>
              <input
                id="file"
                ref="file-second"
                type="file"
                class="input-file"
                @change="importFileExcel"
              />
              <b-button
                variant="outline-primary"
                class="btn-button-file ml-1"
                @click="inputFile"
              >
                <div
                  class="d-flex"
                >
                  <feather-icon
                    icon="UploadIcon"
                    for="file"
                    class="input_label text-primary"
                  />
                  <label
                    class="text-primary ml-50"
                  >Tải lên</label>
                </div>
              </b-button>
            </div>
          </b-form-group>
        </div>
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BFormGroup, BFormTextarea, BButton, BFormInput,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormTextarea,
    BButton,
    BFormInput,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    isNotification: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      content: null,
      dataInput: {
        content: '',
        modelFormData: null,
        nameFile: '',
      },
    }
  },
  methods: {
    async handleSend() {
      const formData = new FormData()
      formData.append('IsSecure', this.dataInput.modelFormData.isSecure)
      formData.append('formFile', this.dataInput.modelFormData.files)
      formData.append('Content', this.dataInput.content)
      formData.append('NameFile', this.dataInput.nameFile)
      this.$emit('handleSend', formData)
    },
    resetState() {
      this.nameFile = null
    },
    inputFile() {
      this.$refs['file-second'].click()
    },
    importFileExcel(e) {
      this.dataInput.nameFile = this.$refs['file-second'].files[0].name
      const tmpFiles = e.target.files || e.dataTransfer.files
      if (!tmpFiles.length) { return }
      const file = tmpFiles[0]
      this.dataInput.modelFormData = {
        files: file,
        isSecure: false,
      }
    },

    resetData() {
      this.dataInput = {
        content: '',
        modelFormData: null,
        nameFile: '',
      }
    },
  },
}
</script>

<style lang="scss">
.modal-accepted {
  .btn-button-file {
    width: 45%;
    height: 38px;
  }
  .input-file {
    display: none;
  }
}
</style>
