<template>
  <div>
    <b-modal
      :id="id"
      centered
      :hide-footer="true"
      :hide-header="false"
      :no-close-on-backdrop="isNoClose"
      size="lg"
      title="Xem hồ sơ xử lý tai nạn"
    ><button-all-header
       :contentBtnAdd="'Thêm người lao động'"
       :arrayExcel="[]"
       :showBtnMultiDelete="showBtnMultiDelete"
       :hideDelete="false"
       :hideImportFile="false"
       :hideExportFile="false"
       :hideDowload="false"
       :hideAdd="false"
       class="custtom-header"
       @search="search($event)"
     />
      <vue-good-table
        :columns="columns"
        :rows="dataTableFileAccident || []"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{ enabled: false, selectOnCheckboxOnly: true }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Chức năng -->
          <span v-if="props.column.field == 'cn'">
            <feather-icon
              icon="DownloadIcon"
              size="18"
              @click="downloadFile(props.row.id, props.row.name)"
            />
          </span>
        </template>
      </vue-good-table>
      <my-pagination
        :totalItems="totalRecordFile"
        :currentPage="urlQueryFile.pageNumber"
        @pageClick="handlePageClick"
      />
    </b-modal>
  </div>
</template>
<script>
import { BBadge } from 'bootstrap-vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    ButtonAllHeader,
    BBadge,
  },
  props: {
    id: {
      type: String,
      default: 'confirm-modal',
    },
    isNoClose: {
      type: Boolean,
      default: true,
    },
    dataTableFileAccident: {
      type: Array,
      default: _ => [],
    },
    totalRecordFile: {
      type: Number,
      default: 1,
    },
    urlQueryFile: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      showBtnMultiDelete: false,
      totalItems: 20,
      currentPage: 10,
      columns: [
        {
          label: 'TÊN FILE',
          field: 'name',
        },
        {
          label: 'Chức năng',
          field: 'cn',
          width: '250px',
          tdClass: 'actiontd',
          thClass: 'actionth',
        },
      ],
    }
  },
  methods: {
    search(val) {
      this.$emit('search', val)
    },

    handlePageClick(pageNumber, pageSize) {
      this.$emit('handlePageClick', pageNumber, pageSize)
    },

    // Tải file có trong giấy phép lao dộng
    downloadFile(id, val) {
      axiosApiInstance({
        url: '/Accident/download-accident-file', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { id },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', val) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },
  },
}
</script>
<style lang="scss">
.actiontd{
  text-align: center;
}
.actionth{
  text-align: center;
}
.text-name {
  color: #227ff4;
}

.light-code {
  background: rgba(34, 127, 244, 0.12);
  border-radius: 4px;
}

.border-status {
  border-radius: 4px;
}
</style>
