<template>
  <div>
    <b-modal
      :id="id"
      centered
      :hide-footer="true"
      :hide-header="false"
      :no-close-on-backdrop="isNoClose"
      size="xl"
      title="Xem danh sách người lao động bị tai nạn"
    ><button-all-header
       :contentBtnAdd="'Thêm người lao động'"
       :arrayExcel="[]"
       :showBtnMultiDelete="showBtnMultiDelete"
       :hideDelete="false"
       :hideImportFile="false"
       :hideExportFile="false"
       :hideDowload="false"
       :hideAdd="false"
       class="custtom-header"
       @search="search($event)"
     />
      <vue-good-table
        :columns="columns"
        :rows="dataTableAccident || []"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{ enabled: false, selectOnCheckboxOnly: true }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column:Mã đơn vị -->
          <span
            v-if="props.column.field === 'code'"
            class="text-nowrap"
          >
            <b-badge
              pill
              class="light-code"
            >
              <span class="text-name">{{ props.row.code }}</span>
            </b-badge>
          </span>

          <!-- Chức năng -->
          <span v-else-if="props.column.field == 'cn' && userData.roleIdentity !== 'EducationDepartment'">
            <feather-icon
              icon="DownloadIcon"
              size="18"
              @click="downloadFile(props.row.id)"
            />
          </span>
        </template>
      </vue-good-table>
      <my-pagination
        :totalItems="totalRecordAccident"
        :currentPage="urlQueryAccident.pageNumber"
        @pageClick="handlePageClick"
      />
    </b-modal>
  </div>
</template>
<script>
import { BBadge } from 'bootstrap-vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'

export default {
  components: {
    VueGoodTable,
    MyPagination,
    ButtonAllHeader,
    BBadge,
  },
  props: {
    id: {
      type: String,
      default: 'confirm-modal',
    },
    isNoClose: {
      type: Boolean,
      default: true,
    },
    dataTableAccident: {
      type: Array,
      default: _ => [],
    },
    totalRecordAccident: {
      type: Number,
      default: 1,
    },
    urlQueryAccident: {
      type: Object,
      default: _ => {},
    },
    userData: {
      type: Object,
      default: _ => {},
    },

  },
  data() {
    return {
      showBtnMultiDelete: false,
      totalItems: 20,
      currentPage: 10,
      columns: [
        {
          label: 'MÃ NGƯỜI LAO ĐỘNG',
          field: 'code',
        },
        {
          label: 'TÊN NGƯỜI LAO ĐỘNG',
          field: 'name',
        },
        {
          label: 'DOANH NGHIỆP',
          field: 'bussiness',
        },
        {
          label: 'TÌNH TRẠNG THƯƠNG TÍCH',
          field: 'statusWorkerString',
        },
        {
          label: 'SỐ NGÀY NGHỈ PHÉP',
          field: 'absent',
        },
        {
          label: 'LOẠI CHẤN THƯƠNG',
          field: 'injuryReason',
        },
        {
          label: this.userData.roleIdentity !== 'EducationDepartment' ? 'Chức năng' : '',
          field: 'cn',
          width: '175px',
          tdClass: 'actiontd',
          thClass: 'actionth',
        },
      ],
    }
  },
  methods: {
    search(val) {
      this.$emit('search', val)
    },

    handlePageClick(pageNumber, pageSize) {
      this.$emit('handlePageClick', pageNumber, pageSize)
    },

    // Tải file có trong giấy phép lao dộng
    downloadFile(id) {
      axiosApiInstance({
        url: '/Accident/file-quick-report-accident', // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { accidentWorkerId: id },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'BaoCaoNhanhTaiNanLaoDong - Tr_n v_n S_m.pdf') // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },
  },
}
</script>
<style lang="scss">
.actiontd{
  text-align: center;
}
.actionth{
  text-align: center;
}
.text-name {
  color: #227ff4;
}

.light-code {
  background: rgba(34, 127, 244, 0.12);
  border-radius: 4px;
}

.border-status {
  border-radius: 4px;
}
</style>
